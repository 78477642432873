import { render } from "./PageNotFound.vue?vue&type=template&id=154d2c75"
import script from "./PageNotFound.vue?vue&type=script&lang=js"
export * from "./PageNotFound.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "154d2c75"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('154d2c75', script)) {
    api.reload('154d2c75', script)
  }
  
  module.hot.accept("./PageNotFound.vue?vue&type=template&id=154d2c75", () => {
    api.rerender('154d2c75', render)
  })

}

script.__file = "src/components/error/PageNotFound.vue"

export default script